@import 'variables';


.ui {
  &.form {

    input[type='text'],
    input[type='email'],
    input[type='textarea'],
    textarea {
      color: white !important;
      padding: 1em !important;
      background: #2d2e30 !important;
    }
  }
}

@media only screen and (max-width: 600px) {
  .ui {
    &.segment.mobile {
      padding: 0 !important;
    }
  }
}