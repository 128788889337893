// Defining the colors
$color-background: #EEE9DC;
$color-dark: #1E3639;
$color-accent: #C9772C;
$color-primary: #3772FF;
$color-secondary: #8D6A9F;
// Color Palette
$semantic-ui-inverted: #1b1c1d;
$color-primary: #0d2538; // Dark blue background
$color-secondary: #2d3e50; // Lighter blue for sections
$color-accent: #f97316; // Orange for CTAs and highlights
$color-text: #e5e7eb; // Off-white for text
$color-subtext: #9ca3af; // Grey for subtext
$color-off-white: #f8f8f8; // Light background
$color-dark: #333; // Dark text
$color-accent: #ffc600; // Accent color - golden/yellow
$color-light-accent: #eaeaea; // Light grey for borders and lines
$color-subtle: #575757; // Subtle text color
$color-light-accent-2: #cacaca; // Light grey for borders and lines
$color-purple-accent: #cdc4dc;