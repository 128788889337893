@media only screen and (max-width: 600px) {
  .nav>* {
    font-size: 1.25em;
  }
}

// @import '../../styles/variables.scss';

// nav { 
//   position: fixed;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 60px;
//   width: 100%;
//   top: 0; 
//   background-color: $white;
// }

// .nav-item { 
//   list-style: none;
//   margin: 0 1.563rem;
//   font-size: 1.563rem;
//   color: $dark;
//   &:hover { 
//     font-weight: bold;
//     cursor: pointer;
//   }
// }

// .active { 
//   font-weight: bold;
//   color: $gold;
// }