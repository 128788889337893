.image-block {
  background: url('https://images.unsplash.com/photo-1487611459768-bd414656ea10?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;
  background-size: cover;
  border-radius: 7px;
}

@media only screen and (max-width: 600px) {
  .image-block {
    border-radius: 0px;
  }

  .content-block {
    text-align: center !important;
  }
}

// Alt backgrounds
// background: url('https://images.unsplash.com/photo-1581906970825-ed79c31cb540?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black no-repeat;
// background: url('https://images.unsplash.com/photo-1487611459768-bd414656ea10?q=80&w=2340&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;
// background: url('https://images.unsplash.com/photo-1609111440973-12f8369e5cd1?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;
// background: url('https://images.unsplash.com/photo-1600351054767-cd897fa01327?q=80&w=2671&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black no-repeat;
// background: url('https://images.unsplash.com/photo-1534866640380-01f8a5cd587e?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;