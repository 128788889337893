@import 'variables';
@import 'overrides';

body {
  color: $color-text;
  margin: 0;
  padding: 0;
  background-color: $semantic-ui-inverted;
}


footer {
  background-color: $color-secondary;
  padding: 2rem 0;

  p {
    color: $color-text;
  }
}


// /***** Slide Left *****/
// .slide-left {
//   animation: 3s slide-left;
// }

// @keyframes slide-left {
//   from {
//     margin-left: 100%;
//   }

//   to {
//     margin-left: 0%;
//   }
// }