// Alt background
//   background: url('https://images.unsplash.com/photo-1534866640380-01f8a5cd587e?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;
// https://images.unsplash.com/photo-1504805572947-34fad45aed93?q=80&w=2607&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
// background: url('https://images.unsplash.com/photo-1481487196290-c152efe083f5?q=80&w=3062&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;

.hero-image {
  position: relative;
  background: url('https://images.unsplash.com/photo-1483058712412-4245e9b90334?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') black center no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.hero-overlay {
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.9));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-grow: 1;
}