div.card {
  width: 100% !important;
  min-height: 100% !important;
  border-radius: 7px !important;
  background: #1c1c1d !important;

  .price-right {
    float: right;
  }

  .header {
    flex-grow: 0 !important;
  }

  .details {
    flex-grow: 2 !important;
  }

  &-flat {
    box-shadow: none !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;

    * {
      color: white !important;
    }

    .extra {
      background-color: rgba(255, 255, 255, 0.1) !important;
      border-top: 1px solid white !important;
      border-radius: 4px;
    }
  }

  &-raised {
    background-color: #f4eede !important;
  }
}

@media only screen and (max-width: 600px) {
  .content-grid {
    padding: 0em 1em !important;
  }
}